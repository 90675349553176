import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../utils/axios";
import polyglot from "../../utils/polyglot";
import { useFetch, useInfiniteFetch, useInterval, useToast } from "../hooks";
import {
  JOB_QUERY_KEY,
  OFFER_STATE_PAID,
  getCancelOfferUrl,
  getValidationOfferUrl,
} from "../../pages/Job/utils";

export const useGetJob = () =>
  useFetch(window.location.pathname + window.location.search, {
    queryKey: JOB_QUERY_KEY,
  });

export const deleteChangeDateAndTime = ({ id, date_time_id }) =>
  api.delete(`/jobs/${id}/date_time_change_requests/${date_time_id}`);

export const usePutCloseJob = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ({ close_reason, close_comment }) =>
      api.put(`/jobs/${id}/close`, { close_reason, close_comment }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([JOB_QUERY_KEY]);
        toast.success(polyglot.t("job.close_success"));
      },
    }
  );
};

export const useUpdateDateTime = ({ id, onSuccess }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ({ date, start_hour }) =>
      api.put(`/jobs/${id}/update_datetime`, { date, start_hour }),
    {
      onSuccess: (res) => {
        if (onSuccess) onSuccess(res);
        queryClient.refetchQueries([JOB_QUERY_KEY]);
        toast.success(polyglot.t("job.update_datetime_success"));
      },
    }
  );
};

export const usePutPublish = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(() => api.put(`/jobs/${id}/publish`), {
    onSuccess: () => {
      queryClient.refetchQueries([JOB_QUERY_KEY]);
      toast.success(polyglot.t("job.publish_success"));
    },
  });
};

export const usePostPhotoToToken = () => {
  const MAX_UPLOAD_PERCENT = 70;
  const STEP = 3;
  const toast = useToast();

  const [progress, setProgress] = useState(null);

  useInterval(
    () => {
      if (progress >= MAX_UPLOAD_PERCENT) {
        setProgress((s) => s + STEP);
      }
    },
    progress >= MAX_UPLOAD_PERCENT && progress <= 95 ? 1000 : null
  );

  return {
    ...useMutation(
      ({ attachment }) => {
        const formData = new FormData();
        setProgress(0);
        formData.append("attachment", attachment);
        return api.post(`/job_photos`, formData, {
          onUploadProgress({ loaded, total }) {
            const realPercent = Math.round((loaded * 100) / total);
            const percent =
              realPercent <= MAX_UPLOAD_PERCENT
                ? realPercent
                : MAX_UPLOAD_PERCENT;
            setProgress(percent);
          },
          timeout: 0,
        });
      },
      {
        onSuccess: () => {
          setProgress(null);
        },
        onError: (err) => {
          toast.danger(err.response.data.error_message);
          setProgress(null);
        },
      }
    ),
    progress,
  };
};

export const useDeletePhoto = () =>
  useMutation(({ id }) => api.delete(`/job_photos/${id}`));

export const usePostPhotos = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ({ photos }) => api.post(`/jobs/${id}/add_photos`, { photos }),
    {
      onSuccess: () => {
        toast.success(polyglot.t("job.informations_update_success"));
        queryClient.refetchQueries([JOB_QUERY_KEY]);
      },
    }
  );
};

export const usePutReopen = ({ id }) =>
  useMutation(
    ({ date, start_hour }) =>
      api.put(`/jobs/${id}/reopen`, { date, start_hour }),
    {
      onSuccess: () => window.location.reload(),
    }
  );

export const useGetComments = ({ id }) => {
  const queryClient = useQueryClient();
  return useInfiniteFetch(`/jobs/${id}/comments`, null, {
    refetchOnWindowFocus: false,
    onSuccess: ({ pages }) => {
      queryClient.setQueryData([JOB_QUERY_KEY], (oldData) => {
        const n = oldData;
        if (n) {
          const lastPageData = pages[pages.length - 1].data;
          n.comments_count = lastPageData.comments?.length;
          if (lastPageData.comments?.length > 0) {
            n.last_comment = {
              body: lastPageData.comments[0].body,
              first_name: lastPageData.comments[0].user.first_name,
            };
          }
        }
        return n;
      });
    },
  });
};

export const usePostComment = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const key = `/jobs/${id}/comments`;
  return useMutation((values) => api.post(key, values), {
    onSuccess: ({ data }) => {
      queryClient.setQueryData([key], (oldData) => {
        const n = oldData;
        n.pages[n.pages.length - 1].data.total_count += 1;
        n.pages[0].data.comments.unshift(data.data);
        return n;
      });
      queryClient.setQueryData([JOB_QUERY_KEY], (oldData) => {
        const n = oldData;
        n.comments_count += 1;
        n.last_comment = {
          body: data.data.body,
          first_name: data.data.user.first_name,
        };
        return n;
      });
    },
    onError: (err) => {
      toast.danger(err?.response?.data?.error_message);
    },
  });
};

export const usePostReportComment = ({ id }) => {
  const toast = useToast();
  return useMutation(({ commentId }) => {
    toast.success(polyglot.t("job.comment_report_success"));
    return api.post(`/jobs/${id}/comments/${commentId}/report`);
  }, {});
};

export const usePutJobInformations = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ({ values, name }) => api.put(`/jobs/${id}/update_${name}`, values),
    {
      onSuccess: () => {
        toast.success(polyglot.t("job.informations_update_success"));
        queryClient.refetchQueries([JOB_QUERY_KEY]);
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};

export const usePutJobPhone = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ({ values, name }) =>
      api.put(`/account/informations/update_${name}`, values),
    {
      onSuccess: () => {
        toast.success(polyglot.t("job.informations_update_success"));
        queryClient.refetchQueries([JOB_QUERY_KEY]);
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};

export const useGetCancelReview = ({ id }) =>
  useFetch(getCancelOfferUrl(id), { refetchOnWindowFocus: false });

export const useCancelReview = () => {
  const toast = useToast();
  return useMutation(
    (values) => api.put(`/offers/${values.id}/cancel`, values),
    {
      onSuccess: ({ data }) => {
        window.location.href = data.redirect_url;
      },
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};

export const useGetReview = ({ id }) =>
  useFetch(getValidationOfferUrl(id), { refetchOnWindowFocus: false });

export const useRateJobber = () => {
  const toast = useToast();
  return useMutation(
    (values) => api.post(`/offers/${values.id}/rates`, values),
    {
      onSuccess: ({ data }) => {
        window.location.href = data.data.redirect_url;
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};

export const useEditRateJobber = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    (values) => api.put(`/offers/${values.id}/rates`, values),
    {
      onSuccess: () => {
        queryClient.refetchQueries([JOB_QUERY_KEY]);
        toast.success("Votre évaluation a bien été envoyé");
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};

export const useGetJobberRate = ({ id }, options) =>
  useFetch(`/offers/${id}/rates/edit`, options);

export const useGetJobberRateNew = ({ id }, options) =>
  useFetch(`/offers/${id}/rates/new`, options);

export const useConfirmPayment = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation((values) => api.put(`/offers/${values.id}/pay`, values), {
    onSuccess: ({ data }, variables) => {
      if (data.data.secure_url) {
        window.location = data.data.secure_url;
      } else if (data?.data?.success_message) {
        toast.success(data.data.success_message);
        // Set paid state to jobber
        queryClient.setQueryData(
          [getValidationOfferUrl(variables.id)],
          (oldData) => {
            const newData = { ...oldData };
            newData.offer.state = OFFER_STATE_PAID;
            newData.automatic_declarative = data?.data.automatic_declarative;
            return newData;
          }
        );
      }
    },
    onError: (err) => {
      toast.danger(err.response.data.error_message);
    },
  });
};

export const useGetPendingOffers = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values) => api.get(`jobs/${values.id}/pending_offers`, { params: values }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData([JOB_QUERY_KEY], (oldData) => {
          const newData = { ...oldData };
          newData.pending_offers = data.data.pending_offers;
          return newData;
        });
      },
    }
  );
};
