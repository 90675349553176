import "react-app-polyfill/stable";
import ReactOnRails from "react-on-rails";
import "../bundles/YoupiJob/assets/app.scss";
import GiftCard from "../bundles/YoupiJob/components/GiftCard";
import { initRailsData } from "../bundles/YoupiJob/modules/actions/rails-actions";
import GiftCardAdd from "../bundles/YoupiJob/pages/GiftCard/GiftCardAdd";
import CategoriesNavigationModalApp from "../bundles/YoupiJob/startup/categories-navigation-modal.app";
import CategoryFormsApp from "../bundles/YoupiJob/startup/category-forms.app";
import CesuButtons from "../bundles/YoupiJob/startup/cesu-buttons.app";
import ContactFilePickerApp from "../bundles/YoupiJob/startup/contact-file-picker.app";
import DashboardApp from "../bundles/YoupiJob/startup/dashboard.app";
import DlApp from "../bundles/YoupiJob/startup/dl-app.app";
import App from "../bundles/YoupiJob/startup/gift-card.app";
import HeaderMenuApp from "../bundles/YoupiJob/startup/header-menu.app";
import JobberCancellationApp from "../bundles/YoupiJob/startup/jobber-cancellation.app";
import JobberReviewApp from "../bundles/YoupiJob/startup/jobber-review.app";
import JobberSelectionTutorialApp from "../bundles/YoupiJob/startup/jobber-selection-tutorial.app";
import JobbersSeoSearchBarApp from "../bundles/YoupiJob/startup/jobbers-seo-search-bar.app";
import LoginOrRegister from "../bundles/YoupiJob/startup/login-or-register.app";
import LostTimeSimulatorApp from "../bundles/YoupiJob/startup/lost-time-simulator.app";
import NewRelationApp from "../bundles/YoupiJob/startup/new-relation.app";
import PublishJobModalApp from "../bundles/YoupiJob/startup/publish-job-modal.app";
import QrcodeGeneratorApp from "../bundles/YoupiJob/startup/qrcode-generator.app";
import ReadMoreApp from "../bundles/YoupiJob/startup/read-more.app";
import RelationApp from "../bundles/YoupiJob/startup/relation-show.app";
import RoleFiltersApp from "../bundles/YoupiJob/startup/role-filters.app";
import SearchBarApp from "../bundles/YoupiJob/startup/search-bar.app";
import TaxCreditSimulatorApp from "../bundles/YoupiJob/startup/tax-credit-simulator.app";
import ToastsApp from "../bundles/YoupiJob/startup/toasts.app";
import TransactionDetailsApp from "../bundles/YoupiJob/startup/transaction-details.app";
import ZoomOnHover from "../bundles/YoupiJob/startup/zoom-on-hover.app";
import JobberBookingApp from "../bundles/YoupiJob/startup/jobber-booking.app";
import store from "../bundles/YoupiJob/store";
import CalendarApp from "../bundles/YoupiJob/startup/calendar.app";
import DatePickerApp from "../bundles/YoupiJob/startup/datepicker.app";
import PaymentMethodsApp from "../bundles/YoupiJob/startup/payment-methods.app";
import ImageCropperApp from "../bundles/YoupiJob/startup/image-cropper.app";
import ZipCodeApp from "../bundles/YoupiJob/startup/zip-code.app";
import AddressApp from "../bundles/YoupiJob/startup/address.app";
import ImageManipulationApp from "../bundles/YoupiJob/startup/image-manipulation.app";
import PhoneNumberApp from "../bundles/YoupiJob/startup/phone-number.app";
import UserPreviewApp from "../bundles/YoupiJob/startup/user-preview.app";
import AdminNotesApp from "../bundles/YoupiJob/startup/admin-notes.app";
import AdminVerifyApp from "../bundles/YoupiJob/startup/admin-verify.app";
import AdministratorsFiltersApp from "../bundles/YoupiJob/startup/administrators-filters.app";
import SendAnalyticsEventApp from "../bundles/YoupiJob/startup/send-analytics-event.app";
import TimeRangeApp from "../bundles/YoupiJob/startup/time-range.app";
import SelectApp from "../bundles/YoupiJob/startup/select.app";
import ProgressCircularApp from "../bundles/YoupiJob/startup/progress-circular.app";
import AvailabilitiesGraphApp from "../bundles/YoupiJob/startup/availabilities-graph.app";
import ImageGalleryApp from "../bundles/YoupiJob/startup/image-gallery.app";
import SeoZipCodeJobsAvailabilityApp from "../bundles/YoupiJob/startup/seo-zip-code-jobs-availability";
import BecomeJobberTitleApp from "../bundles/YoupiJob/startup/become-jobber-title.app";
import AnimatedSelectorPhraseApp from "../bundles/YoupiJob/startup/animated-selector-phrase.app";
import TruckVolumeSimulatorApp from "../bundles/YoupiJob/startup/truck-volume-simulator.app";
import RemovalSearchApp from "../bundles/YoupiJob/startup/removal-search.app";
import JobApp from "../bundles/YoupiJob/startup/job.app";
import MovingVolumeCalculatorApp from "../bundles/YoupiJob/startup/moving-volume-calculator.app";
import VolumeCalculatorApp from "../bundles/YoupiJob/startup/volume-calculator.app";
import MovingBoxesCalculatorApp from "../bundles/YoupiJob/startup/moving-boxes-calculator.app";
import JobberInstantPaymentApp from "../bundles/YoupiJob/startup/jobber-instant-payment.app";
import JobberSearchApp from "../bundles/YoupiJob/startup/jobber-search.app";
import "../src/youpijob.css";

function appStore(props) {
  // INIT redux_store props to react redux store
  if (props) store.dispatch(initRailsData(props));
  return store;
}

ReactOnRails.registerStore({
  appStore,
});

ReactOnRails.register({
  GiftCard,
  GiftCardAdd,
  App,
  SearchBarApp,
  LoginOrRegister,
  JobberSelectionTutorialApp,
  CesuButtons,
  JobbersSeoSearchBarApp,
  DlApp,
  ZoomOnHover,
  NewRelationApp,
  RelationApp,
  CategoriesNavigationModalApp,
  RoleFiltersApp,
  TaxCreditSimulatorApp,
  LostTimeSimulatorApp,
  ContactFilePickerApp,
  DashboardApp,
  HeaderMenuApp,
  TransactionDetailsApp,
  PublishJobModalApp,
  ReadMoreApp,
  ToastsApp,
  QrcodeGeneratorApp,
  JobberReviewApp,
  JobberCancellationApp,
  CategoryFormsApp,
  JobberBookingApp,
  DatePickerApp,
  CalendarApp,
  PaymentMethodsApp,
  ImageCropperApp,
  ZipCodeApp,
  AddressApp,
  ImageManipulationApp,
  PhoneNumberApp,
  UserPreviewApp,
  AdminNotesApp,
  AdminVerifyApp,
  AdministratorsFiltersApp,
  SendAnalyticsEventApp,
  TimeRangeApp,
  SelectApp,
  ProgressCircularApp,
  ImageGalleryApp,
  SeoZipCodeJobsAvailabilityApp,
  BecomeJobberTitleApp,
  TruckVolumeSimulatorApp,
  RemovalSearchApp,
  AnimatedSelectorPhraseApp,
  JobApp,
  AvailabilitiesGraphApp,
  MovingVolumeCalculatorApp,
  VolumeCalculatorApp,
  MovingBoxesCalculatorApp,
  JobberInstantPaymentApp,
  JobberSearchApp,
});
